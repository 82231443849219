import React from "react"
import CTA from "../components/cta"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"

import Sertifika1 from "../images/sertifika-izmir-dokuz-eylul-sergisi.jpg"
import Sertifika2 from "../images/sertifika-izmir-enternasyonal-fuari-1936.jpg"
import Sertifika3 from "../images/sertifika-izmir-enternasyonal-fuari-1940-1.jpg"
import Havlu from "../images/havlu-mrc.jpg"
import Yarn from "../images/yarn-mrc.jpg"
import Background1 from "../images/bg-12.jpg"

const AboutUs = () => (
  <Layout>
    <SEO title="About MRC Tekstil" />

    <PageHeader title="About Us" />

    <div className="h-100 pb-4">
      <div className="main pb-2 w-full text-gray-700">
        <article className="mb-5 grid grid-cols-1 md:grid-cols-3">
          <div
            className="col-span-1 md:col-span-3 text-lg  text-center bg-center object-center"
            style={{ backgroundImage: `url(` + Background1 + `)` }}
          >
            <div className="w-full  bg-white bg-opacity-75 py-24 px-5">
              <p className="text-4xl">
                Everything started in <span className="text-red-500">1842</span>{" "}
                with the domestic yarn trade.
              </p>

              <p className="text-2xl text-gray-600 my-5">
                Our business adventure for{" "}
                <span className="font-bold">6 generations</span> continues with
                the same excitement and discipline.
              </p>
            </div>
          </div>

          <div className="col-span-3 text-center text-2xl pt-10 pb-5">
            Here is the Trade Fair Certificates which we participated in 1929,
            1936 and 1940
          </div>

          <div className="col-span-3 flex items-start justify-center flex-wrap pb-10">
            <div class="max-w-xs  rounded overflow-hidden shadow-lg m-3">
              <img
                src={Sertifika1}
                className="w-full h-64 object-cover"
                alt="izmir dokuz eylül sergisi"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">
                  İzmir Dokuz Eylül Sergisi, 1929
                </div>
              </div>
            </div>

            <div class="max-w-xs rounded overflow-hidden shadow-lg m-3">
              <img
                src={Sertifika2}
                className="w-full"
                alt="izmir dokuz eylül sergisi"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">
                  İzmir Enternasyonal Fuarı, 1936
                </div>
              </div>
            </div>

            <div class="max-w-xs rounded overflow-hidden shadow-lg m-3">
              <img
                src={Sertifika3}
                className="w-full"
                alt="izmir dokuz eylül sergisi"
              />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">
                  İzmir Enternasyonal Fuarı,1940
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 md:col-span-1 text-center  m-0">
            <img
              src={Yarn}
              alt="yarn trade denizli"
              className="object-cover w-full object-center"
              style={{ height: "400px" }}
            />
          </div>
          <div className="col-span-3 md:col-span-2 text-center py-16 px-10 bg-secondary-100">
            <h2 className="text-2xl my-10 font-bold" id="yarn">
              Yarn Trade
            </h2>
            <p className="text-lg">
              We have been the dealership of ISKUR (Kahramanmaraş) company in
              DENİZLİ since 2011.
            </p>
            <p className="text-lg">
              We supply yarn and knitted fabric products produced by ISKUR to
              companies in need in Denizli market.
            </p>
          </div>
          <div className="col-span-3 text-center py-16">
            <h2 className="text-2xl my-10 font-bold" id="iplik">
              Greige Fabric Trade
            </h2>
            <p className="text-lg">
              Since 2013, we have been supplying all kinds of greige fabrics
              such as 20’s Cretone, 30’s Ranforce, 40’s Percale, 40’s Sateen and
              apparel fabrics to our European customers with whom we have good
              connections from Weaving Factories in DENİZLİ and BURSA.
            </p>
            <p className="text-lg">
              We also have the experience of offering world-class Abbrasive
              fabrics to European customers from Denizli.
            </p>
          </div>
          <div className="col-span-3 md:col-span-1 text-center  m-0">
            <img
              src={Havlu}
              alt="havlu dokuma üretimi denizli"
              className="object-cover w-full object-right"
              style={{ height: "300px" }}
            />
          </div>

          <div className="col-span-3 md:col-span-2 text-center py-16 px-10 bg-secondary-100">
            <h2 className="text-2xl my-10 font-bold" id="weaving">
              Towel Fabric Weaving
            </h2>
            <p className="text-lg">
              We have been operating in the field of towel fabric weaving since
              1995. We provide a fast and attentive service to our customers
              with our 6 jacquard and 3 dobby looms.
            </p>
          </div>
          <div className="col-span-3 pt-10 px-5 md:px-40">
            <CTA
              title="MRC Textile"
              message="Contact Us for quote and questions"
              buttonText="Contact Us"
              buttonLink="/contact-us/"
            />
          </div>
        </article>
      </div>
    </div>
  </Layout>
)

export default AboutUs
