import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import ActionButton from "../components/action-button"

const cta = ({ title, message, buttonLink, buttonText }) => (
  <div className="container mx-auto border-secondary-200 border rounded-lg my-2 md:my-5 text-center py-8 md:py-20 px-5 md:px-10">
    <h2 className="text-2xl md:text-4xl text-gray-700">{title}</h2>
    <p className="text-md md:text-2xl text-gray-600 my-5">{message}</p>
    <Link to={buttonLink} className="btn btn-sm ">
      {buttonText}
    </Link>
  </div>
)

cta.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonText: PropTypes.string,
}

cta.defaultProps = {
  title: ``,
  message: ``,
  buttonLink: `/contact/`,
  buttonText: `Contact Us`,
}

export default cta
